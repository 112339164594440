/* styles.css */
@page {
    size: A4;
    margin: 20mm;
    body {
        page-break-inside: avoid;
    }
  }
  
body {
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  h2 {
    margin-top: 30px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
    
  }
  
  th, td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  thead {
    background-color: #f2f2f2;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .instructions {
    margin-top: 30px;
    font-style: italic;
  }
  
  .instructions li {
    margin-bottom: 10px;
  }
  