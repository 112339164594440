

body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}



li .text-primary {
  color:rgb(65, 169, 224)!important;
}

div .text-primary {
  color:rgb(65, 169, 224)!important;
}

.card-title {
  color:rgb(65, 169, 224)!important;
}
.progress-bar {
  background-color: rgb(65, 169, 224)!important;
}
[class~=btn-primary] {
  background-color: rgb(65, 169, 224)!important;
}
.btn-outline-primary:hover {
  background-color: #41A9E0!important;
  color: white!important;

}

.btn-outline-primary {
  border-color: #41A9E0!important;
  color: #41A9E0!important;

}
div .bg-info {
  background-color: #41A9E0!important; 
}
div .badge {
  border-color:#41A9E0!important;
}

.rti--input{
  width: 100%!important;
}


.ck-editor__editable_inline {
    min-height: 400px;
}

#mada path:hover {
  fill: #41A9E0;
}
#mada .active {
  fill: #41A9E0;
}
swiper-slide {
  height: auto !important;
}

.rdt_TableHeader{
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
.rdt_Pagination{
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}


input:focus,
select:focus,
textarea:focus {
  box-shadow: 0 0 5px rgba(86, 156, 223, 0.062) !important;
}


input[type="file"] {
  position: relative;

}

input[type="file"]::file-selector-button {
 
}

.modal-blur.show {
  backdrop-filter: blur(0,1px) !important;
  background-color: rgba(0, 0, 0, 0.135) !important;
}