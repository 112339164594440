.mi-anime-scale :hover {
   
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        top: -4px;
        border: 1px solid #cccccc;
        background-color: white;
      
}

/* Définissez votre animation CSS */
@keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      
      /* Appliquez l'animation aux éléments qui ont la classe "fade-in" */
      .fade-in {
        animation: fadeIn 1.5s ease-in-out;
      }
      