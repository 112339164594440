.moncompte {
    position: relative;
}

.avatar-compte {
    position: absolute;
    height: 100px;
    width: 100px;
    right: 0;
    background-color: aqua;
    top: 0;
}