body,
html {
  font-family: 'Verdana';
}

.p-multiselect-panel {
  min-width: 180px !important;
}

.app {
  min-height: 100vh !important;
}
.container, .container-lg {
  max-width: 1350px !important;
}
#navbar {
  display: block;
  transition: top 0.3s;
}
.test {
animation-name: animatetop;
animation-duration: .8s;
}
@keyframes animatetop {
  from {top: -600px; opacity: 0}
  to {top: 0; opacity: 1}
}

* {
  scrollbar-width: thin !important;
}


.p-datepicker, .p-datepicker * {
  font-size: 12px !important;
  z-index: 99999999999999999 !important; /* Une valeur supérieure à celle du modal */
}


.p-calendar, .p-calendar * {
  font-size: 12px !important; /* Une valeur supérieure à celle du modal */
}

.p-multiselect *{
  font-size: 12px !important;
}
.p-multiselect-filter {
  padding: 3px 8px !important;
}
.p-dropdown *{
  font-size: 12px !important;

}
.p-dropdown-filter {
  padding: 3px 8px !important;
  
}


.truncate-4-lines {
  display: -webkit-box !important;
  -webkit-line-clamp: 5 !important;
  -webkit-box-orient: vertical !important;
/* Ajustez la hauteur en fonction de la taille de la ligne */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.map-container{
  position: relative;
}

.button-app {
  position: absolute;
  top: 3%;
  left: 40%;
}

.loading-map-route {
  position: absolute;
  top: 45%;
  left: 45%;
}
.p-overlaypanel-content{
  font-size: small !important;
}


/* Dans ton fichier CSS global */
@media print {
  /* Ajuste les marges pour réduire l'espace */
  body, html {
    margin: 0;
    padding: 0;
  }

  /* Masque tous les éléments non imprimés, si besoin */
  .no-print {
    display: none;
  }
}
