.photo-container img {
height: 35px !important;
padding: 2px;
}

.photo-container {
    display: flex;
    gap: 3 !important;
 flex-wrap: wrap;
 padding-top:5px !important ;
 padding-bottom: 5px !important;
 border-radius: 3%;
 align-items: center;
 justify-content: center;
 
}